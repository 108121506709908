<template>
    <div class="container">
        <div class="field" @click="this.toggleField()">
            <div class="icon people"></div>
            <div class="field-text">
                <div class="field-text-title" :style="[disabled ? {'color': '#8E8E8E'} : {}]">
                    {{ title }}
                </div>
                <input type="number" v-if="this.number" class="num-input" v-model.lazy="this.number" min="0" @change="this.$emit('update:modelValue', this.number)">
            </div>
            <div class="counter-btns" v-if="this.number">
                <button type="button" @click="this.numberUpdate(1)">+</button>
                <button type="button" @click="this.numberUpdate(-1)">-</button>
            </div>
        </div>
    </div>
 </template>
 
 <script>
 export default {
   name: 'DateTimeField',
   props: {
     icon: String,
     title: String,
     descr: String,
     options: Array,
     disabled: {
       type: Boolean,
       default: false
     },
     modelValue: Object
   },
   watch: {
      modelValue(newVal) {
         this.number = newVal
      }
   },
   data () {
    return {
       active: false,
       number: 0,
    }
   },
   methods: {
    clearNumber() {
       this.number = null
    },
    toggleField(toggle=true) {
       if (!toggle) {
         return
       }
       if (this.number <= 0) {
        this.number = 1;
        this.$emit('update:modelValue', this.number)
       }
    },
    numberUpdate(num) {
        this.number = Number(this.number) + num
        this.$emit('update:modelValue', this.number)
    }
   },
 }
 </script>
 
 <style scoped>
  .counter-btns {
    position: absolute;
    right: 0;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .counter-btns button {
    flex-grow: 1;
    border: solid;
    cursor: pointer;
    border-color: #F0F0F0;
    background-color: white;
  }
  .counter-btns button:hover {
    background-color: #F0F0F0;
  }
  .counter-btns button:last-of-type {
    border-width: 0 0 1px 0;
    border-radius: 0 0 4.41px 0;
  }
  .counter-btns button:first-of-type {
    border-width: 1px 0 0 0;
    border-radius: 0 4.41px 0 0;
  }
  .container {
    position: relative;
    height: 60px;
    width: 406px;
  }
  .datetime {
    position: absolute;
    top: 0;
    left: 0;
    width: 406px;
    height: 60px;
    background: transparent;
    box-sizing: border-box;
    padding-left: 42px;
    padding-top: 5px;
    border: none;
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 400;
    color: #8E8E8E;
  }
  .dropdown {
    position: absolute;
    top: -60px;
    left: 0;
    z-index: 4000;
  }
  .field {
     box-sizing: border-box;
     position: absolute;
     top: 0;
     left: 0;
     height: 60px;
     width: 406px;
     border-style: solid;
     border-radius: 4.41px;
     border-width: 1px;
     border-color: #F0F0F0;
     display: flex;
     flex-direction: row;
     align-items: center;
     cursor: pointer;
  }
  .field-text-title {
     font-family: 'Manrope';
     font-size: 16px;
     font-weight: 600;
     color: #121212;
  }
  .num-input {
     font-family: 'Manrope';
     font-size: 12px;
     font-weight: 400;
     color: #8E8E8E;
     border-width: 0 0 1px 0;
  }
  .num-input::-webkit-outer-spin-button,
  .num-input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  .field-text-select {
     font-family: 'Manrope';
     font-size: 14px;
     font-weight: 400;
     color: #121212;
     text-decoration: underline;
  }
  .marker {
     background-image: url("./img/marker.png");
  }
  .calendar {
     background-image: url("./img/calendar.png");
  }
  .people {
     background-image: url("./img/people.png");
  }
  .icon {
     margin: 10px;
     width: 25px;
     height: 25px;
     background-size: 25px 25px;
  }
 
  @media (max-width: 1180px) {
    .field {
       width: 100%;
    }
    .dropdown {
       width: 100%;
    }
    .container {
       width: 90%;
    }
  }
 
 </style>