<template>
    <div class="about">
        <div class="block-a">
            <h1>
                <span>Dom Tours</span> {{$t('home_view.about_slide.title')}}
            </h1>
            <p>
                (1) {{$t('home_view.about_slide.point_1')}} 
            </p>
            <p>
                (2) {{$t('home_view.about_slide.point_2')}}
            </p>
            <p>
                (3) {{$t('home_view.about_slide.point_3')}}
            </p>
        </div>
        <div class="block-b">
            <div class="space"></div>
            <div class="stats">
                <div class="stat">
                    <p class="title">2500+</p>
                    <p class="descr">{{$t('home_view.about_slide.per_year')}}</p>
                </div>
                <div class="stat">
                    <p class="title">30+</p>
                    <p class="descr">{{$t('home_view.about_slide.drivers')}}</p>
                </div>
                <div class="stat">
                    <p class="title">20</p>
                    <p class="descr">{{$t('home_view.about_slide.years')}}</p>
                    <p class="more">{{$t('home_view.about_slide.license')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutSlide'
    }
</script>

<style scoped>
    .about {
        margin-top: 85px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 1182px;
    }
    .block-a h1 {
        width: 616px;
        font-family: 'Manrope';
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .block-a h1 span {
        color: #A882DD;
    }
    .block-a p {
        font-family: 'Manrope';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        width: 378px;
        margin-bottom: 20px;
    }
    .block-b .space {
        margin-top: -25px;
        height: 147px;
        padding-bottom: 35px;
    }
    .block-b .stats{
        display: grid;
        grid-template-columns: repeat(2, 185px);
        grid-template-rows: repeat(2, 122px);
        gap: 40px;
    }
    .block-b .stat .title {
        color: #A882DD;
        font-family: 'Manrope';
        font-size: 40px;
        font-weight: 700;
        line-height: 55px;
        letter-spacing: 0em;
        text-align: left;
    }
    .block-b .stat .descr {
        font-family: Manrope;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color:#121212;
    }
    .block-b .stat .more {
        font-family: Manrope;
        font-size: 11px;
        font-weight: 300;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color:#121212;
    }     
    
    @media (max-width: 1180px) {
        .about {
            margin-top: 25px;
            flex-direction: column;
            width: 100%;
            padding-left: 20px;
            box-sizing: border-box;
        }

        .block-a h1 {
            width: 100%;
            font-family: Manrope;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
        }

        .block-a p {
            width: auto;
        }

        .block-b .space {
            display: none;
        }

        .block-b {
            display: flex;
            flex-direction: column;
            align-items: center;            
        }

        .block-b .stats {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 250px;
        }

        .block-b .stat .title {
            font-family: Manrope;
            font-size: 25px;
            font-weight: 700;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: center;
        }
        .block-b .stat .descr {
            font-family: Manrope;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;
            color: #121212;

        }
        .block-b .stat .more {
            text-align: center;
        }
    }
</style>