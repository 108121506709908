<template>
    <div class="faq">
        <h1>{{$t('home_view.faq_slide')}}</h1>
        <div class="answers">
            <div class="block-a">
                <AnswerItem
                v-for="(question, index) in localized_questions"
                :ref="'answer_item'+index"
                :key="index" 
                :question="question.question" 
                :answer="question.answer"
                style="margin-bottom: 15px;"
                :index="index"
                :checked="this.checked == index"
                @check="checkQuestions"/>
            </div>
            <div class="block-b">
                <img src="./img/image.png">
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import AnswerItem from './AnswerItem/AnswerItem.vue';

    export default {
        name: 'FAQSlide',
        components: {
            AnswerItem,
        },
        methods: {
            checkQuestions(event) {
                this.checked = event
            }
        },
        data() {
            return {
                checked: 0,
                questions: []
            }
        },
        created() {
            console.log(process.env.VUE_APP_ROOT_API);
            axios.get(process.env.VUE_APP_ROOT_API + '/faq-entries/')
            .then(
                (response) => {
                    this.questions = response.data
                }
            )
            .catch(function (error) {
                console.log(error);
            })
        },
        computed: {
            localized_questions: function () {
                let arr = this.questions
                let locale_question = 'question_' + this.$i18n.locale;
                let locale_answer= 'answer_' + this.$i18n.locale;
                arr.forEach( obj => {
                    obj['question'] = obj[locale_question];
                    obj['answer'] = obj[locale_answer];
                });
                return arr
            }
        }
    }
</script>

<style scoped>
    .faq {
        width: 1182px;
        margin-top: 85px;
        min-height: 600px;
    }
    .answers{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .faq h1 {
        font-family: Manrope;
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .block-b img {
        width: 400px;
        height: 365px;
    }

    @media (max-width: 1180px) {
        .block-a {
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
        }
        .block-b {
            display: none;
        }

        .faq {
            width: auto;
            margin-top: 45px;
            min-height: auto;
        }

        .faq h1 {
            font-family: Manrope;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            padding-left: 10px;
        }
    }
</style>