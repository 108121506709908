<template>
    <div class="directions">
        <h1>{{$t('home_view.directions_slide')}}</h1>
        <div class="cards">
            <div class="card" @click="this.openDirection('/directions/'+direction.slug)" v-for="(direction, index) in this.directions" :key="index">
                <div class="cover" :style="{'background-image': 'url(' + this.optimize_img(direction.cover, 566) +')'}"></div>
                <p class="title">{{ direction.name }}</p>
                <p class="descr">
                    {{ direction.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DirectionsSlide',
        props: {
            directions: Array
        },
        methods: { 
            openDirection(address) {
                this.$router.push(address)
                window.scrollTo(0,0);
            }
        }
    }
</script>
<style scoped>
    .directions{
        width: 1182px;
        margin-top: 85px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
    .directions h1 {
        width: 616px;
        font-family: 'Manrope';
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .directions .cards {
        display: flex;
        flex-direction: row;
        gap: 50px;
    }
    .directions .card {
        cursor: pointer;
        width: 360px;
        height: 566px;
        background-color: #f0f0f0;
        border-radius: 10px;
    }
    .directions .cover {
        background-size: cover;
        background-position: center center;
        height: 292px;
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
    .directions .card .title {
        font-family: 'Manrope';
        font-size: 25px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        padding: 30px;
        padding-bottom: 10px;
    }
    .directions .card .descr {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        padding: 30px;
        padding-top: 10px;
    }

    @media (max-width: 1180px) {
        .directions {
            width: auto;
        }
        .directions .cards {
            display: flex;
            flex-direction: column;
            gap: 25px;
            padding-left: 10px;
            padding-right: 10px;
        }
        .directions h1 {
            width: auto;
            font-family: Manrope;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 20px;
            padding-left: 10px;
        }
        .directions .card {
            width: auto;
            min-height: 566px;
            height: auto;
        }
    }
</style>