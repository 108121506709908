<template>
        <div class="switch">
            <div class="car-option" v-for="(car_class, index) in car_classes" :key="index">
                <input type="radio" 
                :id="car_class.value" 
                name="car_class_selected" 
                :value="car_class.value" 
                :checked="index==checked" 
                @click="$emit('select_class', index)"/>
                <label :for="car_class.value">{{ car_class.name }}</label>
            </div>
        </div>
</template>

<script>
export default {
    name: 'CarClassesSwitch',
    props: {
        car_classes: Array,
    },
    data() {
        return {
            checked: 0
        }
    },

}
</script>

<style scoped>
    .switch {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
    .switch input {
        display: none;
    }
    .switch label {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        background-color: white;
        margin-right: 10px;
        width: 155px;
        height: 38px;
        border-radius: 8.62px;
        border: 0.86px solid #8E8E8E;
        font-family: 'Manrope';
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        padding-top: 6px;

    }
    .car-option:has(input[type="radio"]:checked) label{
        background-color: #A882DD;
        border-color: white;
        color: white;
    }
    @media (max-width: 1180px) {
        .switch {
            padding-left: 10px;
        }
        .switch label {
            width: 104px;
            height: 27px;
            border-radius: 4px;
            font-family: Manrope;
            font-size: 13px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            padding-top: 3px;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
</style>