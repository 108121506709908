<template>
    <div class="main-slide" :style="[direction ? {'background-image': 'url('+this.optimize_img(direction.main_cover, 1920)+')', 'background-size': 'cover'} : {}]">
        <div class="blocks">
            <div class="features" v-if="!direction">
                <ul>
                    <li><p>{{$t('home_view.main_slide.support')}}</p></li>
                    <li><p>{{$t('home_view.main_slide.individual')}}</p></li>
                </ul>
            </div>
            <div class="about" v-if="direction">
                <h1>{{ direction.main_name }}</h1>
                <p>
                    {{ direction.main_description }}
                </p>
            </div>
            <OrderForm :direction="direction" :locations="locations" :prices="prices" :dispo_locations="dispo_locations" :dispo_prices="dispo_prices"/>
            <div class="space" v-if="!direction">
            </div>
            <div class="about_mobile" v-if="direction">
                <h1>{{ direction.main_name }}</h1>
                <p>
                    {{ direction.main_description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import OrderForm from '@/components/OrderForm/OrderForm.vue';

    export default {
        name: "MainSlide",
        components: {
            OrderForm
        },
        props: {
            direction: Object,
            locations: Array,
            dispo_locations: Array,
            prices: Array,
            dispo_prices: Array
        },
    }
</script>

<style scoped>
.error {
    color: red;
    font-size: 12px;
    padding: 5px;
    box-sizing: border-box;
}
.space {
    display: none;
    height: 200px;
    width: 10px;
}
 .main-slide {
    background-image: url("./img/background.png");
    background-size:  cover;
    background-position: center center;
    width: 100%;
    height: 828px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0px;
    margin: 0px;
 }
 .main-slide .blocks{
    margin-top: 45px;
    width: 1182px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
 }
 h1 {
    color: white;
    font-size: 36px;
    font-family: 'Manrope';
    font-weight: 700;
    text-transform: uppercase;
    line-height: 49.18px;
    padding-bottom: 42px;
 }
 .features {
    align-self: flex-end;
    margin-bottom: 85px;
 }
 .features ul {
    color: white;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    display: grid;
    grid-template-columns: 200px 320px;
    padding-inline-start: 40px;
    column-gap: 40px;
 }
 .features li p {
    display: inline;
    text-align: left;
    vertical-align: middle;
 }
 .features li {
    display: table-cell;
    vertical-align: middle;
    list-style: none;
    list-style-position: inside;
    text-indent: -2em;
    padding-left: 1em;
    vertical-align: baseline;
 }
 .features li::before {
    content: '';
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-top: 10px;
    background-size: 25px 25px;
    background-image: url("./img/check.png");
    background-repeat: no-repeat;
    padding-right: 10px;
    position: relative;
    top: 9px;
}
 .order-form {
    align-self: center;
 }
 .order-form form {
    width: 440px;
    min-height: 468px;
    background-color: white;
    border-radius: 8.82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
 }
 .order-form button {
    cursor: pointer;
    background: #FDC943;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 20px;
    border-radius: 9px;
    border: none;
    height: 60px;
    width: 406px;
 }
 .order-form button:hover {
    background: #A882DD;
    color: white;
 }

 .about {
    align-self: baseline;
    margin-top: 135px;
 }
 .about h1 {
    font-family: Manrope;
    font-size: 77px;
    font-weight: 700;
    line-height: 105px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
 }
 .about p {
    width: 411px;
    color: white;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
 }
 .about_mobile {
    align-self: baseline;
    display: none;
    margin: 10px;
    margin-top: 36px;
 }
 .about_mobile h1 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    padding-bottom: 20px;
 }
 .about_mobile p {
    width: 100%;
    color: white;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
 }

 @media (max-width: 1180px) {
    .space {
        display: block;
    }
    .order-form {
        width: 90%;
        margin-bottom: 40px;
    }
    .about {
        display: none;
    }
    .about_mobile {
        display: block;
        margin-bottom: 36px;
    }
    .order-form h1 {
        font-family: Manrope;
        font-size: 23px;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
    }
    .order-form form {
        width: 100%;
    }
    .order-form button {
        width: 90%;
    }
    .main-slide {
        background-color: #121212;
        justify-content: center;
        box-sizing: border-box;
        background-position: 0 0;
        background-size: 100%;
        background-image: url("./img/mobile_background.png");
        background-repeat: no-repeat;
        overflow: visible;
        height: fit-content;
        padding-bottom: 85px;
    }
    .main-slide .blocks {
        width: 100%;
        flex-direction: column;
        flex-flow: column-reverse;
        align-items: baseline;
    }
    .features {
        align-self: center;
        margin-bottom: 0px;
        margin-left: 10px;
    }
    .features ul {
        grid-template-columns: 0.8fr;
        column-gap: 0px;
    }
    .features li {
        padding-left: 0em;
    }
 }
</style>