<template>
    <div class="conditions">
        <h1>
            {{$t('direction_view.conditions_slide.conditions_1')}}
            <br>
            {{$t('direction_view.conditions_slide.conditions_2')}}
        </h1>
        <button @click="this.$router.push('/warning')">{{$t('direction_view.conditions_slide.conditions_button')}}</button>
    </div>
</template>

<script>
    export default {
        name: 'ConditionsSlide',
    }
</script>
<style scoped>
    .conditions{
        width: 1182px;
        margin-top: 85px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .conditions h1 {
        font-family: Manrope;
        font-size: 25px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        text-decoration: underline;
        color: #121212;
    }
    .conditions button {
        margin-top: 20px;
        width: 280px;
        height: 60px;
        border-radius: 9px;
        border: 0px none;
        color: #121212;
        cursor: pointer;
        background: #FDC943;
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: 'Manrope';
        font-weight: 600;
        font-size: 20px;
        border-radius: 9px;
        border: none;
    }

    .conditions button:hover {
        background: #A882DD;
        color: white;
    }
        
    @media (max-width: 1180px) {
    }
</style>