<template>
    <div class="main-slide" :style="[direction ? {'background-image': 'url('+this.optimize_img(direction.main_cover, 1920)+')', 'background-size': 'cover'} : {}]">
        <div class="slogan">
            <p>Важная информация о месте назначения поездки или какое-то акционное предложение</p>
        </div>
        <div class="features">
            <ul>
                <li><p>{{$t('home_view.main_slide.support')}}</p></li>
                <li><p>{{$t('home_view.main_slide.individual')}}</p></li>
                <li><p>{{$t('home_view.main_slide.support')}}</p></li>
                <li><p>{{$t('home_view.main_slide.individual')}}</p></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PriceBannerSlide',
  props: {
    direction: Object,
    locations: Array,
    dispo_locations: Array,
    prices: Array,
    dispo_prices: Array
  },
  data() {
    return {
        tab_selection: "transfers",
        location_from: null,
        from_error: false,
        location_to: null,
        past_error: false,
        to_error: false,
        location_where: null,
        where_error: false,
        datetime: null,
        datetime_error: false,
        passengers: null,
        passengers_error: false,
        today_error: false,
        fields: [
            "location_from",
            "location_to",
            "datetime",
            "passengers"
        ]
    }
  },
}
</script>

<style scoped>
 .slogan {
    font-family: Manrope;
    font-size: 32px;
    font-weight: 700;
    line-height: 43.71px;
    text-align: left;
    color: white;
    max-width: 752px;
 }
 .main-slide {
    background: url("./img/background.jpg");
    background-size:  cover;
    box-sizing: border-box;
    background-position: center center;
    width: 100%;
    min-height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 64px 64px 0 64px;
    margin: 0px;
 }
 .features {
    width: 100%;
    align-self: center;
    margin-bottom: 85px;
 }
 .features ul {
    color: white;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px
 }
 .features li p {
    display: inline;
    text-align: left;
    vertical-align: middle;
 }
 .features li {
    display: table-cell;
    vertical-align: middle;
    list-style: none;
    list-style-position: inside;
    vertical-align: baseline;
 }
 .features li::before {
    content: '';
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-top: 10px;
    background-size: 25px 25px;
    background-image: url("./img/check.png");
    background-repeat: no-repeat;
    padding-right: 10px;
    position: relative;
    top: 9px;
}

@media (max-width: 600px) {
  .main-slide {
    gap: 256px;
  }
}
</style>