<template>
    <footer>
        <h1 class="phrase">{{$t('home_view.tagline')}} <span>Dom Tours!</span></h1>
        <div class="columns">
            <div class="contacts">
                <h1>{{ $t("footer.contacts.title") }}</h1>
                <ul>
                    <li class="icon_marker">22, rue Condotcet <br> 75009 Paris</li>
                    <li class="icon_phone">+33 76 73 68 492 <br> {{ $t("footer.contacts.coast") }}, <br> {{ $t("footer.contacts.alpes") }}</li>
                    <li class="icon_phone">+33 65 17 65 012 <br> {{ $t("footer.contacts.paris") }}</li>
                </ul>
            </div>
            <div class="main">
                <h1>{{ $t("footer.main.title") }}</h1>
                <ul>
                    <li><router-link to="/warning">{{ $t("footer.main.order") }}</router-link></li>
                    <li><router-link to="/warning">{{ $t("footer.main.about") }}</router-link></li>
                    <li><router-link to="/warning">{{ $t("footer.main.directions") }}</router-link></li>
                    <li><router-link to="/warning">{{ $t("footer.main.cars") }}</router-link></li>
                    <li><router-link to="/warning">{{ $t("footer.main.roadmap") }}</router-link></li>
                    <li><router-link to="/warning">{{ $t("footer.main.faq") }}</router-link></li>
                    <li><router-link to="/warning">{{ $t("footer.main.reviews") }}</router-link></li>
                </ul>
            </div>
            <div class="directions">
                <h1>{{ $t("footer.directions") }}</h1>
                <ul>
                    <li v-for="(direction, index) in this.directions" :key="index"><router-link to="/warning">{{ direction.name }}</router-link></li>
                </ul>
            </div>
            <div class="other">
                <h1>{{ $t("footer.other.title") }}</h1>
                <ul>
                    <li><router-link to="/warning">{{ $t("footer.other.conditions") }}</router-link></li>
                    <li><router-link to="/warning">{{ $t("footer.other.policy") }}</router-link></li>
                    <li><router-link to="/warning">Design by Freepik</router-link></li>
                    <li>© 2023 Dom Tours</li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'FooterInfo',
        props: {
            directions: Array
        }
    }
</script>

<style scoped>
    footer {
        width: 100%;
        height: 383px;
        bottom: 0px;
        background: #121212;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    footer div h1 {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    footer div *{
        color: white;
        font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        text-decoration: none;
    }
    footer a:hover{
        font-weight: 600;
        color: #A882DD;
    }
    footer div li {
        margin-bottom: 10px;
    }
    .columns {
        width: 1182px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    li[class^=icon]::before {
        background-size: 20px 20px;
        display: inline-block;
        width: 20px;
        height: 20px;
        content: "";
        position: absolute;
        margin-left: -35px;
    }
    .icon_marker::before {
        background-image: url('./img/marker.png');
    }
    .icon_phone::before {
        background-image: url('./img/phone.png');
    }
    .contacts {
        position: relative;
    }
    .contacts li {
        padding-left: 35px;
    }
    .phrase {
        display: none;
    }

    @media (max-width: 1180px) {

        .phrase, .phrase span {
            display: inline;
            font-family: Manrope;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: center;
            text-transform: uppercase;
            color: white;
        }
        .phrase span {
            color: #FDC943;
        }
        .phrase {
            padding: 20px;
            margin-bottom: 20px;
        }

        footer {
            height: auto;
            width: auto;
            padding-top: 40px;
            align-items: flex-start;
            justify-content: start;
            padding-bottom: 40px;
            flex-direction: column;
        }
        footer .columns {
            width: auto;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            justify-content: start;
            padding-left: 40px;
        }
    }
</style>