<template>
    <button class="contact" @click="this.$router.push('/warning')"></button>
</template>

<script>
export default{
    name: 'ContactButton'
}
</script>

<style scoped>
 .contact {
    z-index: 10000;
    cursor: pointer;
    background: none;
    position: fixed;
    background-image: url("./img/icon.png");
    background-size: 56px 56px;
    width: 56px;
    height: 56px;
    right: 5vw;
    bottom: 15vh;
    border-style: none;
 }
 .contact:hover {
    filter: invert(1) hue-rotate(42deg) saturate(2);
 }

 @media (max-width: 1180px) {
    .contact {
        display: none;
    } 
 }
</style>