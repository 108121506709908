<template>
    <div class="review">
        <p class="text">{{ text }}</p>
        <p class="author">{{ author }}</p>
    </div>
</template>

<script>
    export default {
        name: 'ReviewItem',
        props: {
            text: String,
            author: String
        }
    }
</script>

<style scoped>
    .review {
        box-sizing: border-box;
        flex-shrink: 0;
        width: 280px;
        background-color: white;
        border: 1px solid #8E8E8E;
        border-radius: 10px;
        height: fit-content;
        padding: 10px;
        padding-top: 15px;
    }
    .text {
        font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;
        margin-bottom: 50px;
    }
    .author {
        font-family: Manrope;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #8E8E8E;
    }
</style>