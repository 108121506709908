<template>
    <div class="vignette" :style="[is_window_open ? {'display': 'block'} : {'display': 'none'}]" @click="closePlace"></div>
    <PopUpWindow v-if="this.cars" :open="is_window_open" width="900px" :miniatures="false" :title="this.cars[this.shift_canvas]?.name" :gallery="this.cars[this.shift_canvas]?.car_gallery" @close_popup="closeCar"/>    
    <div class="cars_slider" v-if="this.cars">
        <div class="block-a">
            <vueper-slides 
            :touchable="false" 
            :slide-ratio="1 / 2" 
            class="mobile_slider no-shadow"
            ref="vuepersslide_car"
            :infinite="false"
            :bullets="false"
            @slide="this.select_slide($event)">
                <template #arrow-left>
                    <button class="arrow_left"></button>
                </template>
                <template #arrow-right>
                    <button class="arrow_right"></button>
                </template>
                <vueper-slide
                    v-for="(car, i) in cars"
                    :key="i"
                    :image="car.cover">
                </vueper-slide>
            </vueper-slides>
            <button v-if="this.cars[this.shift_canvas]?.car_gallery.length > 0" class="detailed" @click="openCar">{{$t('home_view.cars_slide.photos')}}</button>
        </div>
        <div class="block-b">
            <h1>{{this.cars[this.shift_canvas]?.name}}</h1>
            <div class="features">
                <ul>
                    <li v-for="(point, index) in this.cars[this.shift_canvas]?.points" :key="index"><p>{{ point }}</p></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import PopUpWindow from '@/components/PopUpWindow/PopUpWindow.vue'
    import 'vueperslides/dist/vueperslides.css'
    export default {
        name: 'CarsSlider',
        props: {
            cars: Array,
        },
        components: { VueperSlides, VueperSlide, PopUpWindow },
        data () {
            return {
                shift_canvas: 0,
                is_window_open: false,
                scroll_top: 0
            }
        },
        computed: {
            cssProps() {
                return {
                    '--shift-canvas': -(-0.1 + this.shift_canvas) * 512 + "px",
                }
            },
            maxLength() {
                return this.cars.length - 1
            }
        },
        methods: {
            select_slide(event) {
                this.shift_canvas = event.currentSlide.index
            },
            openCar() {
                this.scroll_top = window.pageYOffset || document.documentElement.scrollTop
                this.is_window_open = true
                window.scrollTo(0, 0)
            },
            closeCar() {
                this.is_window_open = false
                window.scrollTo(0, this.scroll_top)
            },
        },
        watch: {
            cars: function() {
                this.shift_canvas = 0
            }
        }
    }
</script>

<style scoped>
    .vignette {
        cursor: pointer;
        display: none;
        z-index: 2000;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
    .mobile_slider {
        display: block;
        width: 591px;
    }
    .cars_slider {
        width: 1182px;
        display: flex;
        flex-direction: row;
        gap: 40px;
    }
    .block-b {
        padding-top: 20px;
    }
    .block-b h1 {
        color: black;
        font-family: Manrope;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: none;
        padding-left: 25px;

    }
    button.detailed {
        cursor: pointer;
        border: 2px solid #FDC943;
        color: #FDC943;
        background-color: white;
        padding-top: 8px;
        padding-bottom: 15px;
        font-family: 'Manrope';
        font-weight: 600;
        font-size: 20px;
        border-radius: 9px;
        height: 48px;
        width: 100%;
    }
    button.detailed:hover {
        border: 2px solid #A882DD;
        color: #A882DD;
    }
    button[class^="arrow"]{
        cursor: pointer;
        background-color: rgba(0,0,0,0);
        width: 40px;
        height: 216px;
        background-size: 40px 40px;
        background-position: 0px 0px;
        background-repeat: no-repeat;
        background-image: url("./img/arrow.png");
        border: none;
        position: absolute;
    }
    button[class^="arrow"]:hover{
        background-image: url("./img/arrow_yellow.png");
    }
    button[class^="arrow"]:disabled{
        cursor: default;
        background-image: url("./img/arrow.png");
        opacity: 1;
    }
    .arrow_right {
        transform: scaleX(-1);
        right: 0px;
    }
    .slider {
        width: 591px;
        overflow: hidden;
        position: relative;
        display: none;
    }
    .cars {
        display: flex;
        flex-direction: row;
        margin-left: var(--shift-canvas);
        transition: all 0.5s;
    }
    .features {
        align-self: flex-end;
    }
    .features ul {
        color: #121212;
        font-family: Manrope;
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-inline-start: 40px;
    }
    .features li p {
        display: inline;
        text-align: left;
        vertical-align: middle;
    }
    .features li {
        display: table-cell;
        vertical-align: middle;
        list-style: none;
        list-style-position: inside;
        text-indent: -2em;
        padding-left: 1em;
        vertical-align: baseline;
    }
    .features li::before {
        content: '';
        display: inline-block;
        height: 25px;
        width: 25px;
        margin-top: 10px;
        background-size: 25px 25px;
        background-image: url("./img/check.png");
        background-repeat: no-repeat;
        padding-right: 10px;
        position: relative;
        top: 9px;
    }

    @media (max-width: 1180px) {
        .cars_slider {
            width: 95vw;
            display: flex;
            flex-direction: row;
            gap: 40px;
        }
        .mobile_slider {
            display: block;
            width: 95vw;
        }
        .vueperslides__arrow svg {stroke-width: 10px;}
        .cars_slider {
            width: auto;
            flex-direction: column;
        }
        .slider {
            width: 95vw;
        }
        .slider .car {
            width: auto;
            height: 20vw;
        }

        .slider .car img {
            width: auto;
            height: 20vw;
        }
        .features ul {
            grid-template-columns: 1fr;
        }
        button[class^="arrow"] {
            background-color: rgba(0,0,0,0);
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            background-position: 0 0;
            position: relative;
        }
        .block-b {
            padding: 0px;
        }
    }
</style>