<template>
  <div class="navpanel">
    <div class="navbar">
      <div class="block-a">
        <router-link to="/">
          <div class="logo"></div>
        </router-link>
        <div class="nav">
            <router-link :to="'/directions/'+direction.slug" v-for="(direction, index) in this.directions" :key="index">{{direction.name}}</router-link>
        </div>
      </div>
      <div class="block-b">
        <select class="lang" style="text-transform: uppercase;" v-model="$i18n.locale" @change="localeChange">
          <option 
          v-for="locale in $i18n.availableLocales" 
          :key="`locale-${locale}`" 
          :value="locale" 
          :selected="$i18n.locale == locale"
          >{{ locale }}</option>
        </select>
        <router-link to="/warning">{{ $t("navbar.login") }}</router-link>
      </div>
    </div>
  </div>
  <div class="mobile_panel">
    <router-link to="/">
      <div class="white_logo"></div>
    </router-link>
    <input type="checkbox" name="menu" id="menu" v-model="isMenuOpen" :checked="this.isMenuOpen.length > 0"/>
    <label class="burger" for="menu"></label>
    <label class="veil" for="menu"></label>
    <div class="sidebar">
      <router-link to="/" @click="this.closeMenu">
        <div class="logo"></div>
      </router-link>
      <router-link :to="'/directions/'+direction.slug" v-for="(direction, index) in this.directions" :key="index" @click="this.closeMenu">{{direction.name}}</router-link>
      <select class="lang" style="text-transform: uppercase;" v-model="$i18n.locale" @change="localeChange">
        <option 
        v-for="locale in $i18n.availableLocales" 
        :key="`locale-${locale}`" 
        :value="locale" 
        :selected="$i18n.locale == locale"
        >{{ locale }}</option>
      </select>
      <router-link to="/warning" @click="this.closeMenu">{{ $t("navbar.login") }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    directions: Array
  },
  data() {
    return {
      isMenuOpen: []
    }
  },
  methods: {
    localeChange(event) {
      const sessionStorage = window.sessionStorage;
      sessionStorage.setItem('locale', event.target.value);
      this.closeMenu()
    },
    closeMenu(){
      this.isMenuOpen = []
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .navpanel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  .mobile_panel {
    z-index: 10000;
    display: none;
  }
  @media (max-width: 1180px) {
    .navpanel {
      display: none;
    }
    .mobile_panel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      align-items: center;
      top: 0;
      left: 0;
    }
    .mobile_panel input {
      display: none;
    }
    .white_logo {
      position: absolute;
      width: 92px;
      height: 28px;
      background-image: url("./img/logo.png");
      filter: invert(1);
      background-size: contain;
      left: 20px;
      top: 20px
    }
    .burger {
      position: absolute;
      width: 30px;
      height: 16px;
      background-image: url("./img/burger.png");
      background-size: contain;
      right: 20px;
      top: 20px
    }
    .sidebar {
      position: fixed;
      background-color: white;
      width: 70%;
      height: 100%;
      right: -70%;
      top: 0;
      transition: right 0.5s;
      display: flex;
      flex-direction: column;
      align-items: left;
    }
    .veil {
      position: fixed;
      background-color: rgba(0,0,0,0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: none;
    }
    .mobile_panel:has(input[type="checkbox"]:checked) .sidebar {
      right: 0;
    }

    .mobile_panel:has(input[type="checkbox"]:checked) .veil {
      display: block;
    }
    .logo {
      align-self: center;
      margin-bottom: 60px;
    }
    .sidebar a {
      font-family: Manrope;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: none;
      color: #121212;
      margin-left: 20px;
    }
    .sidebar select {
      margin-top: 100px;
      margin-left: 20px;
    }
  }
  .navbar {
    max-width: 1182px;
    background-color: white;
    width: 100%;
    height: 72px;
    padding: 0px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .block-a {
    top: 17px;
    display: flex;
    flex-flow: row;
  }
  .block-b {
    position: relative;
    top: 26px;
  }
  .logo {
    position: relative;
    top: 17px;
    width: 126px;
    height: 39px;
    background-image: url("img/logo.png");
    background-size: contain;
  }
  .nav {
    margin-left: 74px;
    position: relative;
    top: 26px;
    display: block;
  }
  .navbar a {
    font-family: 'Manrope';
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 33px;
    text-decoration: none;
    color: #121212;   
  }
  .navbar a:hover {
    font-weight: 600;
    color: #A882DD;
  }
  .navbar .block-b a {
    text-decoration: underline;
  }
  .lang {
    cursor: pointer;
    font-family: 'Manrope';
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 33px;
    text-decoration: none;
    color: #121212;  
    border: 0px;
    appearance: none;
    background: transparent;
    background-image: url("./img/arrow.png");
    background-size: 13px 13px;
    background-repeat: no-repeat;
    padding-right: 1rem;
    background-position: 1.3rem;
    margin-left: 25px;
  }
</style>
