import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import DirectionView from '@/views/DirectionView.vue'
import PriceListView from '@/views/PriceListView.vue'
import DispoPriceListView from '@/views/DispoPriceListView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "Main"
    }
  },
  {
    path: '/directions/:direction',
    name: 'direction',
    component: DirectionView,
    meta: {
      title: "Directions"
    }
  },
  {
    path: '/prices/:from/:to/:passengers',
    name: 'prices',
    component: PriceListView,
    meta: {
      title: "Prices"
    }
  },
  {
    path: '/dispo-prices/:where/:passengers',
    name: 'dispo-prices',
    component: DispoPriceListView,
    meta: {
      title: "Dispo prices"
    }
  },
  {
    path: '/warning',
    name: 'warning',
    component: () => import('../views/WarningView.vue'),
    meta: {
      title: "Under Construction"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Dom Tours`;
  next();
})

export default router
