<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 40px;">
    <PriceBannerSlide />
    <div class="container">
      <div class="price_list">
        <h1 style="margin-bottom: 35px;">
          {{$t('price_list_view.transfers')}} {{ location_from }} - {{ location_to }}
        </h1>
        <div class="prices">
          <div class="price_entry" v-for="(entry, index) in this.price_list" :key="index">
            <div class="price_head" v-if="entry?.car_class">
              {{ entry?.car_class.name }}
            </div>
            <div class="price_body" v-if="entry.car">
              <div class="preview">
                <img :src="entry.car.cover">
              </div>
              <div style="display: flex; flex-direction: column; gap: 10px;">
                <p class="car_name">{{ entry.car.name }}</p>
                <div style="display: flex; flex-direction: row; gap: 15px">
                  <p class="counter" v-if="entry?.car_class">
                    <img src="./img/pass.png" width="20px" /> {{ entry?.car_class.number_of_passengers }}
                  </p>
                  <p class="counter" v-if="entry?.car_class">
                    <img src="./img/bag.png" width="20px" /> {{ entry?.car_class.number_of_passengers }}
                  </p>
                </div>
                <ul class="features">
                  <li v-for="(point, index) in entry.car['benefit_' + this.$i18n.locale]" :key="index">
                    <p>{{ point }}</p>
                  </li>
                </ul>
              </div>
              <div class="control">
                <p class="price">{{ entry?.price }} €</p>
                <button>{{$t('price_list_view.choose')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-wrapper">
        <div class="order">
          <div class="field mark">
            <p class="title">Откуда</p>
            <p class="value">{{ location_from }}</p>
          </div>
          <div class="field mark">
            <p class="title">Куда</p>
            <p class="value">{{ location_to }}</p>
          </div>
          <div class="field calendar">
            <p class="title">Дата</p>
            <p class="value">{{ datetime.toISOString().split('T')[0] }}</p>
          </div>
          <div class="field people">
            <p class="title">Количество пассажиров</p>
            <p class="value">{{ this.$route.params.passengers }}</p>
          </div>
          <button @click="showOrderForm = true">Изменить</button>
        </div>
      </div>
    </div>
    <FAQSlide />
    <ReviewsSlide :show_label="false" />

    <!-- Order Form Popup -->
    <div v-if="showOrderForm" class="modal-overlay">
      <div class="modal-content">
        <button @click="showOrderForm = false" class="close-button">
          X
        </button>
        <div style="padding: 32px 8px 32px 8px; display: flex; flex-direction: column; align-items: center; gap: 32px;">
          <div class="modal-text">
            Заполните свои данные, чтобы продолжить выбор трансфера
          </div>
          <OrderForm
            :inside_form = "true"
            :directions="directions" 
            :locations="locations" 
            :prices="prices" 
            :dispo_locations="dispo_locations" 
            :dispo_prices="dispo_prices"
            :initialLocationFrom="this.$route.params.from"
            :initialLocationTo="this.$route.params.to"
            :initialDatetime="this.$route.query.datetime"
            :initialPassengers="this.$route.params.passengers"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import FAQSlide from '@/components/Slides/FAQSlide/FAQSlide.vue';
import ReviewsSlide from '@/components/Slides/ReviewsSlide/ReviewsSlide.vue';
import PriceBannerSlide from '@/components/Slides/PriceBannerSlide/PriceBannerSlide.vue';
import OrderForm from '@/components/OrderForm/OrderForm.vue'; 

export default {
  name: 'PriceListView',
  components: {
    FAQSlide,
    ReviewsSlide,
    PriceBannerSlide,
    OrderForm
  },
  props: {
    directions: Array,
    locations: Array,
    dispo_locations: Array,
    prices: Array,
    dispo_prices: Array
  },
  data() {
    return {
      cars: [],
      car_classes: [],
      showOrderForm: false // Control the visibility of the popup
    };
  },
  created() {
    this.fetchCarClasses();
    this.fetchCars();
  },
  methods: {
    fetchCarClasses() {
      axios.get(process.env.VUE_APP_ROOT_API + '/car-classes/')
        .then(response => {
          this.car_classes = response.data;
        })
        .catch(error => {
          console.error('Error fetching car classes:', error);
        });
    },
    fetchCars() {
      axios.get(process.env.VUE_APP_ROOT_API + '/cars/')
        .then(response => {
          this.cars = response.data;
        })
        .catch(error => {
          console.error('Error fetching cars:', error);
        });
    },
    updateRoute(formData) {
      // Construct the new route based on form data
      const newRoute = {
        name: 'prices',
        params: {
          from: formData.location_from.id,
          to: formData.location_to.id,
          passengers: formData.passengers
        },
        query: {
          datetime: formData.datetime.getTime() / 1000
        }
      };

      // Navigate to the new route
      this.$router.push(newRoute);

      // Close the popup
      this.showOrderForm = false;
    }
  },
  computed: {
    filtered_prices() {
      const from = this.$route.params.from;
      const to = this.$route.params.to;
      const passengers = this.$route.params.passengers;
      return this.prices ? this.prices.filter(price => (
        (
          price.location_from == from && price.location_to == to ||
          price.location_from == to && price.location_to == from
        ) &&
        (
          passengers >= price.min_number_of_passengers &&
          passengers <= price.max_number_of_passengers
        )
      )) : [];
    },
    datetime() {
      return new Date(this.$route.query.datetime * 1000);
    },
    is_night() {
      let a = 22 * 60;
      let b = 24 * 60;
      let c = 8 * 60;
      let time = this.datetime.getHours() * 60 + this.datetime.getMinutes();
      return (time >= a && time <= b) || time <= c;
    },
    price_list() {
      let result = [];
      this.filtered_prices.forEach(price => {
        let car = this.cars.filter(car => car.id == price.car)[0];
        let new_entry = {
          "price": price.price,
          "car": car,
          "car_class": this.car_classes.filter(car_class => car?.car_class == car_class.id)[0]
        };
        result.push(new_entry);
      });
      return result;
    },
    location_from() {
      let result = this.locations.filter(location => location.id == this.$route.params.from)[0];
      return result ? result.name : "";
    },
    location_to() {
      let result = this.locations.filter(location => location.id == this.$route.params.to)[0];
      return result ? result.name : "";
    },
    direction() {
      const directionId = this.locations.find(location => location.id == this.$route.params.from)?.group[0];
      return this.directions.find(direction => direction.id === directionId) || null;
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 90%;
  justify-content: space-around;
  position: relative;
  gap: 100px;
}

.order .field::before {
  background-size: 20px 20px;
  content: '';
  display: inline-block;
  position: relative;
  left: -35px;
  top: 25px;
  width: 20px;
  height: 20px;
}

.mark::before {
  background-image: url('./img/mark.png');
  background-repeat: no-repeat;
}

.calendar::before {
  background-image: url('./img/calendar.png');
}

.people::before {
  background-image: url('./img/people.png');
}

.order-wrapper {
  display: block;
  position: relative;
}

.order .title {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: left;
}

.order .value {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: left;
}

.order {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 25px;
  top: 64px;
}

.container button {
  border: 1px solid #FDC943;
  background-color: white;
  padding: 9.82px 32.47px 13.18px 31.53px;
  gap: 0px;
  border-radius: 6px;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  text-align: center;
}

.features {
  align-self: flex-end;
}

.features ul {
  color: #121212;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
}

.features li p {
  display: inline;
  text-align: left;
  vertical-align: middle;
}

.features li {
  vertical-align: middle;
  list-style: none;
  list-style-position: inside;
  text-indent: -2em;
  padding-left: 1em;
  vertical-align: baseline;
}

.features li::before {
  content: '';
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-top: 10px;
  background-size: 25px 25px;
  background-image: url("./img/check.png");
  background-repeat: no-repeat;
  padding-right: 10px;
  position: relative;
  top: 9px;
}

.price_head {
  width: 196px;
  border-radius: 10px 10px 0px 0px;
  padding: 8px 59px 8px 59px;
  background: #A882DD;
  color: white;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.59px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: #121212;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: left;
}

.price_body {
  box-shadow: 4px 4px 20px 0px #0000000D, -4px -4px 20px 0px #0000000D;
  padding: 51px 38px 51px 38px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 50px;
}

.control {
  justify-content: flex-end;
  margin-left: auto;
}

.control button {
  margin-top: 35px;
  width: 196px;
  height: 43px;
  padding: 9.82px 66.47px 13.18px 66.53px;
  gap: 0px;
  border-radius: 6px;
  border-style: none;
  background-color: #FDC943;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  text-align: center;
}

.price {
  font-family: Manrope;
  font-size: 28px;
  font-weight: 700;
  line-height: 38.25px;
  text-align: center;
  color: #121212;
  position: sticky;
}

.car_name {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.59px;
  text-align: left;
  color: #121212;
}

.price_entry {
  width: 100%;
  margin: 10px;
  align-self: flex-start;
}

.price_entry .photo img {
  max-width: 606px;
}

h1 {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.78px;
  text-align: left;
  font-weight: bold;
}

.prices {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Modal styles */
.modal-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.modal-content {
  background: #8E8E8E;
  border-radius: 5px;
  max-width: 600px; 
}

.modal-text {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.78px;
  text-align: center;
  color: white;
}

.close-button {
  position: relative;
  top: 10px;
  left: calc(100% - 30px); 
  border: none;
  background-color: transparent;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.preview {
  width: 280px
}

.preview img {
  width: 100%
}

@media (max-width: 1200px) {

  .container {
    flex-direction: column-reverse
  }

  .price_body {
    flex-direction: column;
  }

  .order .field::before {
    left: 0px;
  }

  .order .title, .order .value {
    padding-left: 35px;
  }

  .control {
    justify-content: space-around;
    width: 100%;
    margin: unset
  }

  .control button {
    width: 100%
  }

  .preview {
    width: 100%;
  }

}
</style>