<template>
    <div class="places">
        <h1>{{$t('direction_view.places_slide.title')}}</h1>
        <div class="cards">
            <div class="card" @click="$emit('open_place', place)" v-for="(place, index) in this.places" :key="index">
                <div class="cover" :style="{'background-image': 'url(' + this.optimize_img(place.cover, 500) +')'}"></div>
                <p class="title">{{ place.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PlacesSlide',
        props: {
            direction: Object,
            places: Array,
        },
    }
</script>
<style scoped>
    .places{
        width: 1182px;
        margin-top: 85px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 85px;
    }
    .places h1 {
        font-family: 'Manrope';
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .places .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;
        justify-content: center;
    }
    .places .card {
        cursor: pointer;
        width: 360px;
        height: 386px;
        background-color: #f0f0f0;
        border-radius: 10px;
    }
    .places .cover {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 292px;
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
    .places .card .title {
        font-family: Manrope;
        font-size: 25px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
        width: 246px;
        height: 68px;
        align-self: baseline;
        margin: auto;
        margin-top: 10px;
    }

    @media (max-width: 1180px) {
        .places{
            width: 100%;

        }
        .places .cards {
            display: flex;
            flex-direction: row;
            gap: 50px;
            justify-content: center;
        }
    }
</style>