<template>
    <div class="order-form">
      <h1 v-if="!direction && !inside_form">
        {{$t('home_view.main_slide.title1')}}<br>
        {{$t('home_view.main_slide.title2')}}
      </h1>
      <form>
        <TabsPanel :tabs="tabs" v-model="tab_selection" @selectUpdate="clearFields()" />
        <SelectField 
          icon="marker"
          v-if="tab_selection === 'dispo'"
          ref="dispo_location" 
          :title="$t('home_view.main_slide.where')"
          :descr="$t('home_view.main_slide.address')"
          :options="filtered_dispo_locations"
          @click="toggleExcept('dispo_location')"
          v-model="location_where" 
        />
        <div class="error" v-if="tab_selection === 'dispo' && where_error">
          <p>{{$t('home_view.main_slide.where_error')}}</p>
        </div>
  
        <SelectField
          v-if="tab_selection === 'transfers'"
          ref="location_from" 
          :title="$t('home_view.main_slide.from')"
          :descr="$t('home_view.main_slide.address')"
          :options="filtered_locations"
          @click="selectLocationFrom"
          v-model="location_from" 
        />
        <div class="error" v-if="tab_selection === 'transfers' && from_error">
          <p>{{$t('home_view.main_slide.from_error')}}</p>
        </div>

        <SelectField
          v-if="tab_selection === 'transfers'"
          ref="location_to"
          :title="$t('home_view.main_slide.to')"
          :descr="$t('home_view.main_slide.address')"
          :options="locations_to"
          v-model="location_to" 
          @click="toggleExcept('location_to')"
          :disabled="!location_from" 
        />
        <div class="error" v-if="tab_selection === 'transfers' && to_error">
          <p>{{$t('home_view.main_slide.to_error')}}</p>
        </div>
  
        <DateTimeField 
          ref="datetime"
          v-model="datetime" 
          :title="$t('home_view.main_slide.date')" 
          :descr="$t('home_view.main_slide.round_trip')"
        />
        <div class="error" v-if="datetime_error">
          <p>{{$t('home_view.main_slide.datetime_error')}}</p>
        </div>
        <div class="error" v-if="past_error">
          <p>Этот временной период уже больше недоступен</p>
        </div>
        <div class="error" v-if="today_error">
          <p>Уважаемый клиент! Для заказа поездки в данный промежуток времени просьба связаться с менеджером</p>
        </div>
  
        <CounterField
          ref="passengers" 
          icon="people"
          v-model="passengers" 
          :title="$t('home_view.main_slide.number')"
          @click="toggleExcept('passengers')"
        />
        <div class="error" v-if="passengers_error">
          <p>{{$t('home_view.main_slide.passengers_error')}}</p>
        </div>
        <button type="button" style="margin-top: auto; margin-bottom: 20px;" @click="openPrices()">
          {{$t('home_view.main_slide.price')}}
        </button>
      </form>
    </div>
  </template>
  
  <script>
  import SelectField from './CustomFields/SelectField.vue';
  import DateTimeField from './CustomFields/DateTimeField.vue';
  import CounterField from './CustomFields/CounterField.vue';
  import TabsPanel from './TabsPanel/TabsPanel.vue';
  
  export default {
    name: 'OrderForm',
    components: {
      SelectField,
      DateTimeField,
      CounterField,
      TabsPanel
    },
    props: {
      inside_form: Boolean,
      direction: Object,
      locations: Array,
      dispo_locations: Array,
      prices: Array,
      dispo_prices: Array,
      initialLocationFrom: Object, 
      initialLocationTo: Object,   
      initialLocationWhere: Object,
      initialDatetime: [Date, String, Number], 
      initialPassengers: Number     
    },
    data() {
      return {
        tab_selection: 'transfers',
        location_from: null,
        from_error: false,
        location_to: null,
        past_error: false,
        to_error: false,
        location_where: null,
        where_error: false,
        datetime: null,
        datetime_error: false,
        passengers: null,
        passengers_error: false,
        today_error: false,
        fields: [
          "location_from",
          "location_to",
          "datetime",
          "passengers"
        ]
      };
    },
    mounted() {
      this.location_from = this.locations.filter((location) => location.id == this.initialLocationFrom)[0];
      this.location_to = this.locations.filter((location) => location.id == this.initialLocationTo)[0];
      this.location_where = this.initialLocationWhere;
      this.datetime = this.initialDatetime ? new Date(this.initialDatetime * 1000) : null;
      this.passengers = this.initialPassengers ? this.initialPassengers : null;
    },
    watch: {
      $route() {
        this.clearFields();
      }
    },
    methods: {
      openPrices() {
        this.resetValidation(); // Reset validation before checking
  
        if (!this.validateForm()) {
          return; // Exit if validation fails
        }
  
        if (this.tab_selection === 'transfers') {
          this.$router.push({
            name: 'prices',
            params: {
              from: this.location_from.id,
              to: this.location_to.id,
              passengers: this.passengers
            },
            query: {
              datetime: this.datetime.getTime() / 1000
            }
          }).then(() => {
            // Optionally reload the page after navigation
            window.location.reload();
          });
        } else {
          this.$router.push(this.url_dispo_prices);
        }
      },
      validateForm() {
        let isValid = true;
  
        if (this.tab_selection === 'transfers' && !this.location_from) {
          this.from_error = true;
          isValid = false;
        }
  
        if (this.tab_selection === 'transfers' && !this.location_to) {
          this.to_error = true;
          isValid = false;
        }
  
        if (this.tab_selection === 'dispo' && !this.location_where) {
          this.where_error = true;
          isValid = false;
        }
  
        if (!this.datetime) {
          this.datetime_error = true;
          isValid = false;
        }
  
        const today = new Date();
        if (this.datetime && this.datetime.getTime() < today.getTime()) {
          this.past_error = true;
          isValid = false;
        }
  
        // Disable same-day booking
        if (this.datetime && this.datetime.toDateString() === today.toDateString()) {
          this.today_error = true;
          isValid = false;
        }
  
        if (!this.passengers || this.passengers < 1) {
          this.passengers_error = true;
          isValid = false;
        }
  
        return isValid; 
      },
      selectLocationFrom() {
        this.$refs.location_to.clearSelection();
        this.toggleExcept('location_from');
      },
      toggleExcept(exc) {
        this.fields.forEach((field) => {
          if (field !== exc && this.$refs[field]) {
            this.$refs[field].toggleField(false);
          }
        });
      },
      clearFields() {
        this.resetValidation(); 
  
        if (this.$refs.location_from) {
          this.$refs.location_from.clearSelection();
        }
        if (this.$refs.location_to) {
          this.$refs.location_to.clearSelection();
        }
        if (this.$refs.dispo_location) {
          this.$refs.dispo_location.clearSelection();
        }
        if (this.$refs.datetime) {
          this.$refs.datetime.clearDate();
        }
        if (this.$refs.passengers) {
          this.$refs.passengers.clearNumber();
        }
  
        this.location_from = null;
        this.location_to = null;
        this.location_where = null;
        this.datetime = null;
        this.passengers = null;
      },
  
      resetValidation() {
        this.from_error = false;
        this.to_error = false;
        this.where_error = false;
        this.datetime_error = false;
        this.past_error = false;
        this.passengers_error = false;
        this.today_error = false;
      }
    },
    computed: {
      tabs() {
        return [
          {
            name: this.$t('home_view.main_slide.transfers'),
            value: "transfers"
          },
          {
            name: this.$t('home_view.main_slide.dispo'),
            value: "dispo"
          }
        ];
      },
      filtered_locations() {
        if (this.direction) {
          const id = this.direction.id;
          return this.locations.filter((location) => location.group.includes(id));
        }
        return this.locations;
      },
      filtered_dispo_locations() {
        if (this.direction) {
          const id = this.direction.id;
          return this.dispo_locations.filter((location) => location.group.includes(id));
        }
        return this.dispo_locations;
      },
      locations_to() {
        const from = this.location_from;
        if (!from) {
          return [];
        }
        let filteredPrices = this.prices.filter((price) => price.location_from === from.id);
        let locationIds = new Set(filteredPrices.map((price) => price.location_to));
        filteredPrices = this.prices.filter((price) => price.location_to === from.id);
        filteredPrices.forEach((elem) => locationIds.add(elem.location_from));
        return this.filtered_locations.filter((location) => locationIds.has(location.id));
      },
      url_dispo_prices() {
        let result = "";
        if (this.location_where && this.passengers) {
          result += "/dispo-prices/";
          result += this.location_where.id + "/";
          result += this.passengers;
        }
        return result;
      }
    }
  };
  </script>
  
  <style scoped>
  .error {
    color: red;
    font-size: 12px;
    padding: 5px;
    box-sizing: border-box;
  }
  .space {
    display: none;
    height: 200px;
    width: 10px;
  }
  h1 {
    color: white;
    font-size: 36px;
    font-family: 'Manrope';
    font-weight: 700;
    text-transform: uppercase;
    line-height: 49.18px;
    padding-bottom: 42px;
  }
  .order-form {
    align-self: center;
  }
  .order-form form {
    width: 440px;
    min-height: 468px;
    background-color: white;
    border-radius: 8.82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .order-form button {
    cursor: pointer;
    background: #FDC943;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 20px;
    border-radius: 9px;
    border: none;
    height: 60px;
    width: 406px;
  }
  .order-form button:hover {
    background: #A882DD;
    color: white;
  }
  .about {
    align-self: baseline;
    margin-top: 135px;
  }
  .about h1 {
    font-family: Manrope;
    font-size: 77px;
    font-weight: 700;
    line-height: 105px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px;
  }
  .about p {
    width: 411px;
    color: white;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .about_mobile {
    align-self: baseline;
    display: none;
    margin: 10px;
    margin-top: 36px;
  }
  .about_mobile h1 {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0;
    padding-bottom: 20px;
  }
  .about_mobile p {
    width: 100%;
    color: white;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  @media (max-width: 1180px) {
    .space {
      display: block;
    }
    .order-form {
      width: 90%;
      margin-bottom: 40px;
    }
    .about {
      display: none;
    }
    .about_mobile {
      display: block;
      margin-bottom: 36px;
    }
    .order-form h1 {
      font-family: Manrope;
      font-size: 23px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      color: white;
    }
    .order-form form {
      width: 100%;
    }
    .order-form button {
      width: 90%;
    }
  }
  </style>