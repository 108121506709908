<template>
    <div class="cars">
        <h1>{{$t('home_view.cars_slide.title')}}</h1>
        <CarClassesSwitch :car_classes="localized_car_classes" @select_class="this.pass_new"/>
        <CarsSlider :cars="localized_car_classes[class_selected]?.cars"/>
    </div>
</template>

<script>
import axios from 'axios';
import CarClassesSwitch from './CarClassesSwitch/CarClassesSwitch.vue';
import CarsSlider from './CarsSlider/CarsSlider.vue';
export default {
    name: 'CarsSlide',
    components: {
        CarClassesSwitch,
        CarsSlider
    },
    data() {
        return {
            class_selected: 0,
            car_classes: []
        }
    },
    methods: {
        pass_new(event) {
            this.class_selected = event;
        }
    },
    created() {
        axios.get(process.env.VUE_APP_ROOT_API + '/car-classes/')
        .then(
            (response) => {
                this.car_classes = response.data
            }
        )
        .catch(function (error) {
            console.log(error);
        })
    },
    computed: {
        localized_car_classes: function () {
            let arr = this.car_classes
            let locale_points = 'points_' + this.$i18n.locale;
            arr.forEach( obj => {
                let cars = obj['cars'];
                cars.forEach( car => {
                    car['points'] = car[locale_points]
                })
            });
            return arr
        },
    }
}
</script>

<style scoped>
    .cars {
        margin-top: 85px;
        width: 1182px;
        height: 418px;
    }
    h1 {
        font-family: Manrope;
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    @media (max-width: 1180px) {
        .cars {
            margin-top: 85px;
            width: 95vw;
            height: auto;
        } 
        h1 {
            font-family: Manrope;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            padding-left: 10px;
        }
    }

</style>