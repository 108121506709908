<template>
   <DatePicker mode="dateTime" v-model="date" :popover="popover" color="yellow">
      <template #default="{ togglePopover }">
         <div class="container">
               <div class="field" @click="() => togglePopover()">
                  <div class="icon calendar"></div>
                  <div class="field-text">
                     <div class="field-text-title" :style="[disabled ? {'color': '#8E8E8E'} : {}]">
                           {{ title }}
                     </div>
                     <div v-if="descr && !this.date" class="field-text-descr">
                           {{ descr }}
                     </div>
                     <div v-else class="field-text-descr">
                        {{ this.selectedDate }}
                     </div>
                  </div>
               </div>
         </div>
      </template>
   </DatePicker>
</template>

<script>
import moment from 'moment';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import { ref } from 'vue';

export default {
  name: 'DateTimeField',
  props: {
    icon: String,
    title: String,
    descr: String,
    options: Array,
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: Object
  },
  components: {
   DatePicker
  },
  watch: {
      modelValue(newVal) {
         this.date = newVal
      }
   },
  data () {
   return {
      active: false,
      selected: null,
      date: null,
      popover: ref({
         visibility: 'hover-focus',
         autoHide: true,
         placement: 'bottom-start',
         showDelay: 0,
         hideDelay: 110,
      }),
   }
  },
  methods: {
   clearDate() {
      this.date = null
   },
   toggleField(toggle=null) {
      if (this.disabled) {
         return;
      }
      if (toggle !== null) {
         this.active = toggle
      } else {
         this.active = !this.active
      }
   },
   selectOption(value) {
      this.selected = value;
      this.active = false;
      this.$emit('update:modelValue', this.selected);
   }
  },
  computed: {
   selectedDate() {
      this.$emit('update:modelValue', this.date)
      return moment(this.date).format('MMMM Do YYYY, h:mm a')
   }
  } 
}
</script>

<style scoped>
 .container {
   position: relative;
   height: 60px;
   width: 406px;
 }
 .datetime {
   position: absolute;
   top: 0;
   left: 0;
   width: 406px;
   height: 60px;
   background: transparent;
   box-sizing: border-box;
   padding-left: 42px;
   padding-top: 5px;
   border: none;
   font-family: 'Manrope';
   font-size: 12px;
   font-weight: 400;
   color: #8E8E8E;
 }
 .dropdown {
   position: absolute;
   top: -60px;
   left: 0;
   z-index: 4000;
 }
 .field {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 406px;
    border-style: solid;
    border-radius: 4.41px;
    border-width: 1px;
    border-color: #F0F0F0;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
 }
 .field-text-title {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    color: #121212;
 }
 .field-text-descr {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 400;
    color: #8E8E8E;
 }
 .field-text-select {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 400;
    color: #121212;
    text-decoration: underline;
 }
 .marker {
    background-image: url("./img/marker.png");
 }
 .calendar {
    background-image: url("./img/calendar.png");
 }
 .people {
    background-image: url("./img/people.png");
 }
 .icon {
    margin: 10px;
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
 }

 @media (max-width: 1180px) {
   .field {
      width: 100%;
   }
   .dropdown {
      width: 100%;
   }
   .container {
      width: 90%;
   }
 }

</style>