<template>
  <NavBar :directions="localized_directions"/>
  <ContactButton/>
  <router-view :directions="localized_directions" :locations="localized_locations" :dispo_locations="localized_dispo_locations" :prices="this.prices" :dispo_prices="this.dispo_prices"/>
  <FooterInfo :directions="localized_directions"/>
</template>

<script>
  import axios from 'axios';
  import NavBar from './components/NavBar/NavBar.vue';
  import FooterInfo from './components/FooterInfo/FooterInfo.vue';
  import ContactButton from './components/ContactButton/ContactButton.vue';
  export default {
    components: {
      NavBar,
      FooterInfo,
      ContactButton
    },
    data() {
      return {
        directions: [],
        locations: [],
        dispo_locations: [],
        prices: [],
        dispo_prices: [],
      }
    },
    beforeCreate() {
        axios.get(process.env.VUE_APP_ROOT_API + '/directions/')
        .then(
            (response) => {
                this.directions = response.data
            }
        )
        .catch(function (error) {
            console.log(error);
        });
        axios.get(process.env.VUE_APP_ROOT_API + '/locations/')
        .then(
            (response) => {
                this.locations = response.data
            }
        )
        .catch(function (error) {
            console.log(error);
        })
        axios.get(process.env.VUE_APP_ROOT_API + '/dispo-locations/')
        .then(
            (response) => {
                this.dispo_locations = response.data
            }
        )
        .catch(function (error) {
            console.log(error);
        })
        axios.get(process.env.VUE_APP_ROOT_API + '/prices/')
        .then(
            (response) => {
                this.prices = response.data
            }
        )
        .catch(function (error) {
            console.log(error);
        })
        axios.get(process.env.VUE_APP_ROOT_API + '/dispo-prices/')
        .then(
            (response) => {
                this.dispo_prices = response.data
            }
        )
        .catch(function (error) {
            console.log(error);
        })
    },
    computed: {
        localized_directions: function () {
            let arr = this.directions
            let locale_name = 'name_' + this.$i18n.locale;
            let locale_description = 'description_' + this.$i18n.locale;
            let locale_main_name = 'main_name_' + this.$i18n.locale;
            let locale_main_description = 'main_description_' + this.$i18n.locale;
            arr.forEach( obj => {
                obj['name'] = obj[locale_name];
                obj['description'] = obj[locale_description];
                obj['main_name'] = obj[locale_main_name];
                obj['main_description'] = obj[locale_main_description];
            });
            return arr
        },
        localized_locations: function () {
            let arr = this.locations
            let locale_name = 'name_' + this.$i18n.locale;
            arr.forEach( obj => {
                obj['name'] = obj[locale_name];
            });
            return arr
        },
        localized_dispo_locations: function () {
            let arr = this.dispo_locations
            let locale_name = 'name_' + this.$i18n.locale;
            arr.forEach( obj => {
                obj['name'] = obj[locale_name];
            });
            return arr
        },
        fresh_prices: function () {
          return this.prices
        }
    },
  }
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Manrope');
#app {
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  height: 100%;
  width: 100%;
}
</style>
