<template>
    <div class="reviews-slide" ref="reviews_slide" :style="cssProps">
        <div class="title">
            <div class="block-a">
                <h1>{{$t('home_view.reviews_slide')}}</h1>
            </div>
            <div class="block-b">
                <button class="arrow_left" @click="this.prev" :disabled="!(this.shift_canvas > 0)"></button>
                <button class="arrow_right" @click="this.next" :disabled="!(this.shift_canvas < this.maxLength)"></button>
            </div>
        </div>
        <div class="reviews">
            <ReviewItem v-for="(review, index) in localized_reviews" :key="index" :text="review.text" :author="review.author"/>
        </div>
        <h1 class="phrase" v-if="this.show_label">{{$t('home_view.tagline')}} <span>Dom Tours!</span></h1>
    </div>
</template>

<script>
    import axios from 'axios';
    import ReviewItem from './ReviewItem/ReviewItem.vue';
    export default {
        name: 'ReviewsSlide',
        data() {
            return {
                shift_canvas: 0,
                reviews: []
            }
        },
        components: {
            ReviewItem
        },
        props: {
            show_label: {
                type: Boolean,
                default: true
            }
        },
        created() {
            axios.get(process.env.VUE_APP_ROOT_API + '/reviews/')
            .then(
                (response) => {
                    this.reviews = response.data
                }
            )
            .catch(function (error) {
                console.log(error);
            })
        },
        computed: {
            localized_reviews: function () {
                let arr = this.reviews
                let locale_author = 'author_' + this.$i18n.locale;
                let locale_text= 'text_' + this.$i18n.locale;
                arr.forEach( obj => {
                    obj['author'] = obj[locale_author];
                    obj['text'] = obj[locale_text];
                });
                return arr
            },
            cssProps() {
                return {
                    '--shift-canvas': -(this.shift_canvas) * 280 + "px",
                }
            },
            maxLength() {
                let width = this.$refs.reviews_slide ? this.$refs.reviews_slide.offsetWidth : 1182
                let padding = (document.body.clientWidth - width) / 2;
                return this.reviews.length - Math.round((document.body.clientWidth - padding) / 300);
            }
        },
        methods: {
            next() {
                console.log();
                this.shift_canvas ++;
            },
            prev() {
                this.shift_canvas --;
            }
        },
    }
</script>

<style scoped>
    .reviews-slide{
        display: flex;
        flex-direction: column;
        width: 1182px;
        min-height: 325px;
        overflow: hidden;
    }
    .title {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .block-a h1{
        font-family: Manrope;
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .block-a h1{
        color: #121212;
    }
    button[class^="arrow"]{
        cursor: pointer;
        background-color: rgba(0,0,0,0);
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-image: url("./img/arrow.png");
        border: none;
    }
    button[class^="arrow"]:hover{
        background-image: url("./img/arrow_yellow.png");
    }
    button[class^="arrow"]:disabled{
        cursor: default;
        background-image: url("./img/arrow.png");
        opacity: 0.5;
    }
    .arrow_right {
        transform: scaleX(-1);
        margin-left: 20px;
    }
    .block-b {
        width: 400px;
    }
    .reviews {
        margin-left: var(--shift-canvas);
        transition: margin 0.5s;
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 150px;
        width: 100%;
    }
    .phrase {
        font-family: Manrope;
        font-weight: 700;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 20px;
        color: white;
        font-size: 44px;
        text-transform: uppercase;
    }
    h1 span {
        color: #FDC943;
    }

    @media (max-width: 1180px) {
        .phrase {
            display: none;
        }
        .reviews-slide {
            width: 100%;
            margin-top: 40px;
        }
        .title {
            flex-direction: column;
            padding-left: 10px;
            margin-bottom: 30px;
        }
        .block-a h1 {
            font-family: Manrope;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 5px;
        }
        .block-b {
            width: auto;
        }
        .reviews {
            padding-left: 10px;
            margin-bottom: 50px;
        }
        button[class^="arrow"]{
            width: 30px;
            height: 30px;
            background-size: 30px 30px;
        }
    }
</style>