<template>
    <div class="tabs">
        <div v-for="tab in tabs" :key="tab.value" class="tab">
            <div class="tab_data">
                <input type="radio" :id="tab.value" :value="tab.value" name="tabs" :checked="this.modelValue == tab.value" @change="this.selectTab(tab.value)"/>
                <label :for="tab.value">
                    {{ tab.name }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TabsPanel",
    props: {
        tabs: Array,
        modelValue: String,
    },
    methods: {
        selectTab(value) {
            this.$emit('update:modelValue', value);
            this.$emit('selectUpdate')
        }
    }
}
</script>

<style scoped>
    .tabs {
        display: flex;
        flex-direction: row;
    }
    .tab input[type="radio"] {
        display: none;
    }
    .tab label {
        cursor: pointer;
        font-family: Manrope;
        font-size: 17px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0em;
        display: inline-block;
        width: 100%;
        height: 100%;
        padding-top: 20px;
        box-sizing: border-box;
    }
    .tab:first-child {
        border-radius: 8.82px 0px 0px 0px;
    }
    .tab:last-child {
        border-radius: 0px 8.82px 0px 0px;
    }
    .tab:has(input[type="radio"]:checked) {
        background-color: white;
    }
    .tab {
        width: 220px;
        height: 77px;
        display: flex;
        background-color: #F0F0F0;
    }
    .tab_data {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    @media (max-width: 1180px) {
        .tab {
            width: 100%;
        }
        .tabs {
            width: 100%;
        }

        .tab label {
            font-size: 13px;
        }
    }
</style>