<template>
    <div class="answer_item">
        <div class="plus">
            <div class="bg"></div>
            <input
            type="checkbox"
            :id="'plus_button'+this.index" 
            class="plus_button"
            :value="this.index"
            :checked="this.opened"
            @change="this.check"
            />
            <label 
            :for="'plus_button'+this.index"
            ></label>
        </div>
        <p class="question">{{ question }}</p>
        <p class="answer">{{ answer }}</p>
    </div>
</template>
<script>
    export default {
        name: 'AnswerItem',
        props: {
            question: String,
            answer: String,
            checked: Number,
            index: Number
        },
        methods: {
            check(){
                this.$emit('check', this.index);
            }
        },
        computed:{
            opened: function(){ return this.checked;}
        }


    }
</script>
<style scoped>
    .answer_item {
        width: 680px;
        max-height: 60px;
        background-color: #F0F0F0;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20px;
        padding-left: 30px;
        position: relative;
        overflow: hidden;
        transition: max-height 0.5s;
    }
    .question {
        font-family: Manrope;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .answer {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }
    .plus {
        z-index: 10;
        position: absolute;
        right: 10px;
        top: 10px;
        transition: background-image 0.1s;
    }
    .plus_button {
        display: none;
    }
    .plus label{
        content: "";
        width: 40px;
        height: 40px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 18.5px 18.5px;
        background-image: url("./img/plus.png");
        display: inline-block;
        cursor: pointer;
        transition: transform 0.1s;
    }
    .plus_button:checked + label {
        transform: rotate(45deg);
    }
    .answer_item:has(.plus_button:checked) {
        max-height: 300px;
    }
    .bg {
        z-index: -1;
        position: absolute;
        left:0;
        top:0;
        background-image: url('./img/bg.png');
        opacity: 0;
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
        transition: opacity 0.1s;
    }
    .plus:has(.plus_button:checked) .bg {
        opacity: 1;
    }

    @media (max-width: 1180px) {
        .answer_item {
            width: 100%;
        }
        .answer_item:has(.plus_button:checked) {
            height: fit-content;
        }

        .question {
            font-family: Manrope;
            font-size: 13px;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
        }

        .answer {
            font-size: 12px;
            line-height: 15px;
        }

        .plus label {
            width: 25px;
            height: 25px;
            background-size: 9.25px 9.25px;
        }

        .bg {
            width: 25px;
            height: 25px;
            background-size: 25px 25px;
        }
    }
</style>