<template>
    <div class="roadmap">
        <div class="info">
            <h1>{{$t('home_view.roadmap_slide.title')}}</h1>
            <div class="line">
                <svg width="983" height="555" viewBox="0 0 983 555" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 28.0002C68.3333 0.66688 237 -29.5998 373 68.0002C509 165.6 683.667 115.334 754 78.0002C873 14.8344 1083 78.0002 926 412C909 464 821.189 608.122 703 532C585 456 408 390 316 515" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="20 20"/>
                </svg>
            </div>
            <div class="step step-one">
                <p class="title">
                    {{$t('home_view.roadmap_slide.step1_title')}}
                </p>
                <p class="descr">
                    {{$t('home_view.roadmap_slide.step1_descr')}}
                </p>
            </div>
            <div class="icon icon-step-one"></div>
            <div class="step step-two">
                <p class="title">
                    {{$t('home_view.roadmap_slide.step2_title')}}
                </p>
                <p class="descr">
                    {{$t('home_view.roadmap_slide.step2_descr')}}
                </p>
            </div>
            <div class="icon icon-step-two"></div>
            <div class="step step-three">
                <p class="title">
                    {{$t('home_view.roadmap_slide.step3_title')}}
                </p>
                <p class="descr">
                    {{$t('home_view.roadmap_slide.step3_descr')}}
                </p>
            </div>
            <div class="icon icon-step-three"></div>
            <div class="step step-final">
                <p class="title">
                    {{$t('home_view.roadmap_slide.step_final_title')}}
                </p>
                <p class="descr">
                    {{$t('home_view.roadmap_slide.step_final_descr')}}
                </p>
            </div>
            <div class="icon icon-step-final"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RoadMapSlide'
    }
</script>

<style scoped>
    .roadmap {
        margin-top: 85px;
        background-image: url("./img/background.png");
        width: 100%;
        height: 924px;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .roadmap .info {
        width: 1182px;
        position: relative;
    }
    .info h1 {
        font-family: 'Manrope';
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #FDC943;
        position: absolute;
        top: 85px;
        text-transform: uppercase;
    }
    .line {
        z-index: 500;
        position: absolute;
        top: 200px;
    }
    .step {
        z-index: 1000;
        position: absolute;
    }
    .step-one {
        left: 40px;
        top: 240px;
        width: 270px;
    }
    .step-two {
        top: 0px;
        left: 550px;
        top: 350px;
        width: 316px;
    }
    .step-three {
        top: 0px;
        left: 860px;
        top: 760px;
        width: 268px;
    }
    .step-final {
        left: 190px;
        top: 760px;
        width: 268px;
    }
    .icon {
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
        position: absolute;
    }
    .icon-step-one {
        background-image: url("./img/step_one.png");
        top: 200px;
        left: 0px;
        z-index: 2000;
    }
    .icon-step-two {
        background-image: url("./img/step_two.png");
        top: 305px;
        left: 515px;
        z-index: 2000;
    }
    .icon-step-three {
        background-image: url("./img/step_three.png");
        top: 715px;
        left: 815px;
        z-index: 2000;
    }
    .icon-step-final {
        background-image: url("./img/step_final.png");
        top: 715px;
        left: 290px;
        z-index: 2000;
    }
    .step .title {
        font-family: 'Manrope';
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        color: white;
        margin-bottom: 10px;
    }
    .step .descr {
        font-family: 'Manrope';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        color: white;
    }
    .step-final p {
        width: 100%;
        position: relative;
        text-align: center;
    }

    @media (max-width: 1180px) {
        .line {
            display: none;
        }
        .roadmap {
            height: fit-content;
            flex-direction: column;
            overflow-y: hidden;
        }
        .roadmap .info {
            box-sizing: border-box;
            padding-left: 10px;
            padding-top: 40px;
            width: 100vw;
        }
        .info h1 {
            top: 0;
            left: 0;
            position: relative;
            font-family: Manrope;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            padding-bottom: 20px;
        }
        .icon {
            display: none;
            left: 0;
            top: 0;
            position: relative;
        }
        .step {
            left: 0;
            top: 0;
            position: relative;
            width: auto;
            text-align: left;
            padding-bottom: 70px;
            padding-left: 50px;
        }

        .step .title {
            font-family: Manrope;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
        }
        .step .descr {
            font-family: Manrope;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
        }
        .step-final p{
            text-align: left;
        }
        .step::before {
            background-size: 35px 35px;
            width: 35px;
            height: 35px;
            position: absolute;
            content: '';
            top: 0px;
            left: 0px;
        }
        .step-one::before{
            background-image: url("./img/step_one.png");
        }
        .step-two::before{
            background-image: url("./img/step_two.png");
        }
        .step-three::before{
            background-image: url("./img/step_three.png");
        }
        .step-final::before{
            background-image: url("./img/step_final.png");
        }

        .info::before {
            display: inline-block;
            height: 100%;
            width: 100px;
            border-left: 2px dashed #9A9A9A;
            content: '';
            position: absolute;
            top: 90px;
            left: 27px;
        }
    }
</style>