<template>
  <div class="vignette" :style="[is_window_open ? {'display': 'block'} : {'display': 'none'}]" @click="closePlace"></div>
  <div class="slides">
    <MainSlide ref="main_slide_dir" :direction="this.localized_direction" :locations="locations" :prices="prices" :dispo_prices="dispo_prices" :dispo_locations="dispo_locations"/>
    <ConditionsSlide/>
    <PlacesSlide :places="this.localized_places" @open_place="openPlace"/>
    <DirectionsGallerySlide :direction_gallery="this.direction_gallery"/>
    <PopUpWindow :open="is_window_open" :title="sel_place?.name" :miniatures="true" :description="sel_place?.description"  :gallery="place_gallery" @close_popup="closePlace"/>
  </div>
</template>

<script>
import axios from 'axios';
import PopUpWindow from '@/components/PopUpWindow/PopUpWindow.vue'
import MainSlide from '@/components/Slides/MainSlide/MainSlide.vue'
import ConditionsSlide from '@/components/Slides/ConditionsSlide/ConditionsSlide.vue'
import PlacesSlide from '@/components/Slides/PlacesSlide/PlacesSlide.vue'
import DirectionsGallerySlide from '@/components/Slides/DirectionsGallerySlide/DirectionsGallerySlide.vue'
export default {
  name: 'DirectionView',
  components: {
    MainSlide,
    ConditionsSlide,
    PlacesSlide,
    DirectionsGallerySlide,
    PopUpWindow
  },
  props: {
    directions: Array,
    locations: Array,
    dispo_locations: Array,
    prices: Array,
    dispo_prices: Array
  },
  meta:{
    title: 'Login'
  },
  data() {
    return {
      direction_gallery: [],
      place_gallery: [],
      places: [],
      sel_place: {},
      is_window_open: false,
      scroll_top: 0
    }
  },
  methods: {
    openPlace(event) {
      this.scroll_top = window.scrollY || document.documentElement.scrollTop
      this.sel_place = event
      this.getPlaceGallery()
      this.is_window_open = true
      window.scrollTo(0, 0);

    },
    closePlace() {
      this.is_window_open = false
      window.scrollTo(0, this.scroll_top)
    },
    getPlaces: function() {
        axios.get(process.env.VUE_APP_ROOT_API + '/places/')
        .then(
            (response) => {
                let unfiltered_places = response.data
                this.places = unfiltered_places.filter(p => p.direction == this.localized_direction.id)
            }
        )
        .catch(function (error) {
            console.log(error);
        })
    },
    getDirectionGallery: function() {
        axios.get(process.env.VUE_APP_ROOT_API + '/directions-gallery/')
        .then(
            (response) => {
                let unfiltered_gallery = response.data
                this.direction_gallery = unfiltered_gallery.filter(p => p.direction == this.localized_direction.id)
            }
        )
        .catch(function (error) {
            console.log(error);
        })
    },
    getPlaceGallery: function() {
        axios.get(process.env.VUE_APP_ROOT_API + '/places-gallery/')
        .then(
            (response) => {
                let unfiltered_gallery = response.data
                this.place_gallery = unfiltered_gallery.filter(p => p.place == this.sel_place.id)
            }
        )
        .catch(function (error) {
            console.log(error);
        })
    },
  },
  computed: {
    localized_direction: function() {
      let direction = this.directions.filter(d => d.slug == this.$route.params.direction)[0]
      return direction;
    },
    localized_places: function() {
      let locale_name = 'name_' + this.$i18n.locale;
      let locale_descr = 'description_' + this.$i18n.locale;
      let arr = this.places;
      arr.forEach( obj => {
          obj['name'] = obj[locale_name];
          obj['description'] = obj[locale_descr];
      });
      return arr;
    },
  },
  created() {
    this.getPlaces()
    this.getDirectionGallery()   
  },
  watch:{
    $route() {
      this.getPlaces()
      this.getDirectionGallery()
    }
  }
}
</script>

<style scoped>

  .vignette {
    cursor: pointer;
    display: none;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .slides {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1200px) {

    .bottom {
      height: 1343px;
      background-image: url("./img/bg.png");
      background-size: auto 1696px;
    }

  }
</style>
