<template>
  <div class="slides">
    <MainSlide :locations="locations" :prices="prices" :dispo_prices="dispo_prices" :dispo_locations="dispo_locations"/>
    <AboutSlide/>
    <DirectionsSlide :directions="directions"/>
    <CarsSlide/>
    <RoadMapSlide/>
    <div class="bottom">
      <FAQSlide/>
      <ReviewsSlide/>
    </div>
  </div>
</template>

<script>
import MainSlide from '@/components/Slides/MainSlide/MainSlide.vue'
import AboutSlide from '@/components/Slides/AboutSlide/AboutSlide.vue'
import DirectionsSlide from '@/components/Slides/DirectionsSlide/DirectionsSlide.vue'
import RoadMapSlide from '@/components/Slides/RoadMapSlide/RoadMapSlide.vue'
import CarsSlide from '@/components/Slides/CarsSlide/CarsSlide.vue'
import FAQSlide from '@/components/Slides/FAQSlide/FAQSlide.vue'
import ReviewsSlide from '@/components/Slides/ReviewsSlide/ReviewsSlide.vue'
export default {
  name: 'HomeView',
  components: {
    MainSlide,
    AboutSlide,
    DirectionsSlide,
    RoadMapSlide,
    CarsSlide,
    FAQSlide,
    ReviewsSlide
  },
  props: {
    directions: Array,
    locations: Array,
    dispo_locations: Array,
    prices: Array,
    dispo_prices: Array
  },
  meta:{
    title: 'Login'
  },
}
</script>

<style scoped>
  .slides {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1200px) {

    .bottom {
      height: 1343px;
      background-image: url("./img/bg.png");
      background-size: auto 1696px;
    }

  }
</style>
