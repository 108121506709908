import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import { languages } from './i18n'
const sessionStorage = window.sessionStorage;
const lang = sessionStorage.getItem('locale');
const messages = Object.assign(languages)
const i18n = createI18n({
  legacy: false,
  locale: lang || navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages
})

let app = createApp(App)

app.mixin({
  methods: {
    optimize_img: function(address, size) {
      return `${process.env.VUE_APP_OPTIMIZE}?size=${size}&format=png&src=${address}`;
    },
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    getScreenWidth() {
      return window.screen.width
    }
  }
})

app.use(router)
app.use(i18n)
app.mount('#app')
