<template>
    <div class="directions-gallery">
        <div class="slides vueper">
            <vueper-slides
                style="border-radius: 10px"
                class="no-shadow vueper"
                ref="vueperslides1"
                :touchable="false"
                fixedHeight="100%"
                fade
                :autoplay="false"
                :bullets="false">
                <template #arrow-left>
                    <button class="arrow_left"></button>
                </template>
                <template #arrow-right>
                    <button class="arrow_right"></button>
                </template>
                <vueper-slide
                    style="border-radius: 10px; cursor: pointer;"
                    v-for="(slide, i) in direction_gallery"
                    :key="i"
                    :image="this.optimize_img(slide.image, 1000)"
                    class="vueper"
                    @click="this.openInNewTab(slide.image)">
                </vueper-slide>
            </vueper-slides>
        </div>
    </div>
    <div class="miniatures">
        <div class="miniature" v-for="(slide, i) in direction_gallery" :key="i" @click="$refs.vueperslides1.goToSlide(i)">
            <img :src="this.optimize_img(slide.image, 100)">
        </div>
    </div>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    export default {
        name: 'DirectionsGallerySlide',
        props: {
            direction_gallery: Array
        },
        components: { VueperSlides, VueperSlide },
    }
</script>
<style scoped>
    .vueper {
        height: 617px;
        width: 780px;
        object-fit: cover;
    }
    .miniatures {
        margin-top: 20px;
        margin-bottom: 100px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 780px;
        gap: 5px;
        justify-content: flex-start;
        z-index: 100;
        margin-left: auto;
        margin-right: auto;
    }

    .miniature {
        border-radius: 5px;
        cursor: pointer;
        display: block;
        overflow: hidden;
    }

    .miniature img{
        border-radius: 5px;
        width: 80px;
        height: 80px;
        object-fit: cover;
        object-position: center center;
    }

    button[class^="arrow"]{
        cursor: pointer;
        background-color: rgba(0,0,0,0);
        width: 40px;
        height: 617px;
        background-size: 40px 40px;
        background-position: 0px 0px;
        background-repeat: no-repeat;
        background-image: url("./img/arrow.png");
        border: none;
        position: absolute;
    }
    button[class^="arrow"]:hover{
        background-image: url("./img/arrow_yellow.png");
    }
    button[class^="arrow"]:disabled{
        cursor: default;
        background-image: url("./img/arrow.png");
        opacity: 1;
    }
    .arrow_right {
        transform: scaleX(-1);
        right: 0px;
    }
    .directions-gallery {
        width: 1182px;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .directions-gallery h1 {
        width: 616px;
        font-family: 'Manrope';
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .directions-gallery .cards {
        display: flex;
        flex-direction: row;
        gap: 50px;
    }
    .directions-gallery .card {
        cursor: pointer;
        width: 360px;
        height: 566px;
        background-color: #f0f0f0;
        border-radius: 10px;
    }
    .directions-gallery .cover {
        background-size: cover;
        background-position: center center;
        height: 292px;
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
    .directions-gallery .card .title {
        font-family: 'Manrope';
        font-size: 25px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        padding: 30px;
        padding-bottom: 10px;
    }
    .directions-gallery .card .descr {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        padding: 30px;
        padding-top: 10px;
    }

    @media (max-width: 1180px) {
        .miniatures {
            display: none;
        }
        .directions-gallery {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        .directions-gallery .cards {
            display: flex;
            flex-direction:column;
            gap: 50px;
        }
        .directions-gallery .card {
            cursor: pointer;
            width: 100px;
            background-color: #f0f0f0;
            border-radius: 10px;
        }
        .vueper {
            width: 95vw;
            height: 90vw;
            margin-bottom: 50px;
        }
    }
</style>