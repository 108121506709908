<template>
  <h1>{{ location_where }}</h1>
  <div class="prices">
    <div class="price_entry" v-for="(entry, index) in this.price_list" :key="index">
      <h2 v-if="entry.car">{{ entry.car.name }}</h2>
      <div class="photo" v-if="entry.car">
        <img :src="entry.car.cover">
        <p>{{ entry.price }}</p>
      </div>
      <p v-if="entry.car_class">passengers: {{ entry.car_class.max_number_of_passengers }}</p>
      <ol v-if="entry.car">
        <li v-for="(benefit, index) in entry.car.benefit" :key="index">{{ benefit }}</li>
      </ol>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'DispoPriceListView',
  components: {
  },
  props: {
    directions: Array,
    locations: Array,
    dispo_locations: Array,
    prices: Array,
    dispo_prices: Array
  },
  data() {
      return {
          cars: [],
          car_classes: []
      }
  },
  created() {
    axios.get(process.env.VUE_APP_ROOT_API + '/car-classes/')
    .then(
        (response) => {
            this.car_classes = response.data
        }
    )
    .catch(function (error) {
        console.log(error);
    })
    axios.get(process.env.VUE_APP_ROOT_API + '/cars/')
    .then(
        (response) => {
            this.cars = response.data
        }
    )
    .catch(function (error) {
        console.log(error);
    })
  },
  computed: {
    filtered_dispo_prices: function() {
        const where = this.$route.params.where;
        const passengers = this.$route.params.passengers;
        return this.dispo_prices.filter(price => (
          price.destination == where &&
          (
            passengers >= price.min_number_of_passengers &&
            passengers <= price.max_number_of_passengers
          )
        )
      )
    },
    localized_car_classes: function () {
        let arr = this.car_classes
        let locale_benefit = 'benefit_' + this.$i18n.locale;
        arr.forEach( obj => {
            let cars = obj['cars'];
            cars.forEach( car => {
                car['benefit'] = car[locale_benefit]
            })
        });
        return arr
    },
    price_list: function() {
      let result = [];
      this.filtered_dispo_prices.forEach((price) => {
        let car = this.cars.filter(car => car.id == price.car)[0]
        let new_entry = {
          "price": price.price,
          "car": car,
          "car_class": this.car_classes.filter(car_class => car.car_class == car_class.id)[0]
        };
        result.push(new_entry);
      })
      console.log(result);
      return result;
    },
    location_where: function() {
      let result = this.dispo_locations.filter(location => location.id == this.$route.params.where)[0];
      if (result) {
        return result.name
      }
      return ""
    },
  },
}
</script>

<style scoped>
  .price_entry {
    width: 606px;
    height: 506px;
    background-color: #f0f0f0;
    margin: 10px;
    overflow: hidden;
    border-radius: 10px;
    border: solid 1px black;
  }
  .price_entry .photo img {
   max-width: 606px;
  }
  h1 {
    font-family: Manrope;
    font-size: 36px;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
  }
  .prices {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    
  } 
</style>
