<template>
   <div class="container">
      <div class="field" @click="this.toggleField()" :style="[disabled ? {'background': '#F0F0F0', 'cursor': 'not-allowed'} : {}]">
         <div class="icon marker"></div>
         <div class="field-text">
            <div class="field-text-title" :style="[disabled ? {'color': '#8E8E8E'} : {}]">
                  {{ title }}
            </div>
            <div v-if="descr && !this.selected" class="field-text-descr">
                  {{ descr }}
            </div>
            <div v-if="this.selected" class="field-text-select">
                  {{ this.selected.name }}
            </div>
         </div>
      </div>
      <div class="dropdown" :style="[active ? {'display': 'block'}: {'display': 'none'}]">
         <p v-for="(option, index) in this.options" :key="index" @click="this.selectOption(option)">{{ option.name }}</p>
      </div>
   </div>
</template>

<script>
export default {
  name: 'SelectField',
  props: {
    icon: String,
    title: String,
    descr: String,
    options: Array,
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: Object
  },
  data () {
   return {
      active: false,
      selected: null
   }
  },
  watch: {
   modelValue(newVal) {
      this.selected = newVal
   }
  },
  methods: {
   clearSelection() {
      this.selected = null
   },
   toggleField(toggle=null) {
      if (this.disabled) {
         return;
      }
      if (toggle !== null) {
         this.active = toggle
      } else {
         this.active = !this.active
      }
   },
   selectOption(value) {
      this.selected = value;
      this.active = false;
      this.$emit('update:modelValue', this.selected);
   }
  }
}
</script>

<style scoped>
 .container {
   position: relative;
   height: 60px;
   width: 406px;
 }
 .dropdown {
   z-index: 1000;
   padding-left: 45px;
   box-sizing: border-box;
   z-index: 1000;
   position: absolute;
   top: 59px;
   width: 406px;
   max-height: 150px;
   background-color: white;
   border-style: solid;
   border-radius: 0 0 4.41px 4.41px;
   border-width: 0 1px 1px 1px;
   border-color: #F0F0F0;
   overflow-y: auto;
 }
 .dropdown p {
   cursor: pointer;
 }
 .dropdown p:hover {
   background-color: #F0F0F0;
 }
 .field {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 406px;
    border-style: solid;
    border-radius: 4.41px;
    border-width: 1px;
    border-color: #F0F0F0;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
 }
 .field-text-title {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    color: #121212;
 }
 .field-text-descr {
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 400;
    color: #8E8E8E;
 }
 .field-text-select {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 400;
    color: #121212;
    text-decoration: underline;
 }
 .marker {
    background-image: url("./img/marker.png");
 }
 .calendar {
    background-image: url("./img/calendar.png");
 }
 .people {
    background-image: url("./img/people.png");
 }
 .icon {
    margin: 10px;
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
 }

 @media (max-width: 1180px) {
   .field {
      width: 100%;
   }
   .dropdown {
      width: 100%;
   }
   .container {
      width: 90%;
   }
 }

</style>